import { CommonModule } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, Optional, ViewChild, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { map } from 'rxjs';
import { TransactionSourceService } from 'app/service/transactionSource/transactionSource.service';
import { MatSelectModule } from '@angular/material/select';
import { Company } from 'app/types/domain/profile';
import { AuthService } from 'app/service/auth.service';
import { TranslocoModule } from '@jsverse/transloco';
import { CompanyService } from 'app/service/company/company.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditResult } from '@yukawa/chain-base-angular-domain';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [
    CommonModule,
    DropzoneModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatSelectModule
  ],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss'
})
export class UploadComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;
  files: File[] = [];
  progress: number = 0;
  uploading: boolean = false;
  companies: Company[] = [];
  selectedCompany?: number;

  constructor(
    private _configService: ConfigService,
    private _transactionSourceService: TransactionSourceService,
    private _companyService: CompanyService,
    private _authService: AuthService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: {company: number, sourceId: string}, 
    @Optional() @Inject(MatDialogRef) public dialogRef: MatDialogRef<UploadComponent> 
    ) {
      if(_authService.isAdmin()){
        this._companyService.companies.subscribe((comps) => {
          this.companies = comps;
        })
        this._companyService.loadCompanies();
      }
      if(dialogData?.company){
        this.selectedCompany = dialogData.company;
      }
  }

  ngAfterViewInit(): void {
    if(this.dialogData?.sourceId){
      this.fileDropEl.nativeElement.removeAttribute('multiple');
    }
  }

  onFileDropped($event:any) {
    this.prepareFilesList($event);
  }
  onFileSelected($event:any){
    const target = $event.target as HTMLInputElement;
    this.prepareFilesList(Array.from(target.files || []));
  }

  uploadFiles(){
    if(this.files.length === 0){
      return;
    }
    if(this.dialogData?.sourceId !== undefined){
      this.updateFile()
      if(this.dialogRef){
        this.dialogRef.close();
      }
      return;
    }
    if( (this._authService.isAdmin() || this._authService.isSupport()) && this.selectedCompany === undefined){
      return;
    }
    this.uploading = true;
    for(let file of this.files){
      this._transactionSourceService.uploadSingleFile(file, this.selectedCompany)
      .pipe(
         map((event:HttpEvent<EditResult>) => {
          this.updateProgress(event);
         })
      )
      .subscribe(
        {
          next: (response) => {
          },
          error: (error) => {
            console.error(error);
          }
          ,complete: () => {
            this.files = [];
            this.uploading = false;
            if(this.dialogRef){
              this.dialogRef.close();
            }
          }
        }
      );
    }
  }
  updateFile() {
    this._transactionSourceService.putFile(this.files[0], this.dialogData.sourceId)
      .pipe(
         map((event:HttpEvent<EditResult>) => {
           this.updateProgress(event);
         })
      )
      .subscribe(
        {
          next: (response) => {
            
          },
          error: (error) => {
            console.error(error);
          }
          ,complete: () => {
            this.files = [];
            this.uploading = false;
            if(this.dialogRef){
              this.dialogRef.close();
            }
          }
        }
      );
  }
  updateProgress(event: HttpEvent<EditResult>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const progress = Math.round(100 * event.loaded / (event.total || 1));
        this.progress = progress;
        return { status: 'progress', message: progress };
      case HttpEventType.Response:
        return { status: 'complete', message: 'Upload complete' };
      default:
        return event;
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files:File[] | null) {
    if(files !== null){
      this.prepareFilesList(files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: File[]) {
    for (const item of files) {
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes:number | undefined, decimals = 2):string {
    if(bytes === undefined){
      return '';
    }
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  isAdmin():boolean {
    return this._authService.isAdmin();
  } 
}
