<div class="flex items-center h-full">
  <mat-card class="uploadCard mx-auto mt-8">
    <mat-card-header class="mb-2 flex justify-center">
      <mat-card-title class="">Upload Trade Data</mat-card-title>
      <mat-card-subtitle>Drag and Drop your files here</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="container " appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" />
        <mat-icon class="h-60 w-60" svgIcon="mat_outline:download"></mat-icon>
        <h3>Click or drag your files to this area to upload</h3>
        <h3>you can also upload files by</h3>
        <button mat-basic-button color="primary" (click)="fileDropRef.click()" class="cursor-pointer z-20 mt-2"
          mat-button>clicking here</button>
        <div>
          <span class="text-sm ml-2">Files must be less than 50 MB in size</span>
        </div>
      </div>
      <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
          <mat-icon svgIcon="mat_outline:upload_file" width="45px" alt="file" class="mr-2"></mat-icon>
          <div class="w-full">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <mat-progress-bar mode="determinate" [value]="progress" *ngIf="uploading"></mat-progress-bar>
          </div>
          <button mat-icon-button *ngIf="!uploading">
            <mat-icon svgIcon="mat_outline:delete" class="delete" width="20px" alt="file"
              (click)="deleteFile(i)"></mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="gap-4 flex-wrap">
      <div class="flex items-center">
        <mat-icon svgIcon="mat_outline:info"></mat-icon>
        <span class="text-sm ml-2">You can upload up to 15 Files</span>
      </div>
      <div class="flex flex-grow"></div>
      <mat-form-field *ngIf="isAdmin()" class="dense-input">
        <mat-select [(ngModel)]="selectedCompany" [placeholder]="'COMPANY.SELECT' | transloco"
          [disabled]="!!dialogData">
          <mat-option *ngFor="let company of companies" [value]="company.companyId">
            {{ company.info?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button color="primary" class="p-4" [disabled]="files.length == 0" (click)="uploadFiles()">Upload
        Files</button>
    </mat-card-actions>
  </mat-card>
</div>