import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { TransactionSourceDataSource } from "./transactionSource.datasource";
import { BehaviorSubject, Observable, Subscription, map, of, reduce, take, tap } from "rxjs";
import { DataImportStatus, SupportStatus, TradeFileEvent, Transaction } from "app/types/domain/transaction";
import { TransactionSource } from "app/types/extended.transaction.types";
import { ConfigService } from "@yukawa/chain-base-angular-client";
import { EditResult, QueryResult } from "@yukawa/chain-base-angular-domain";
import { TransactionSourceFilter } from "app/types/domain/transaction";
import { createCleanedPartial } from "app/utils/object.utils";

import { RestAspect } from "@yukawa/chain-base-angular-client";

@Injectable({
    providedIn: 'root'
  })
export class TransactionSourceService implements OnDestroy {
    
    private _httpClient = inject(HttpClient);
    private _transactionsSources$ = new  BehaviorSubject<Transaction[]>([]);

    private _totalRows$ = new BehaviorSubject<number>(0);

    defaultFilter: TransactionSourceFilter = {
        pager: {
            firstResult: 0,
            pageSize: 10
        },
        orderDir: "DESC",
        orderBy: "sourceId"
    }

    constructor(
        private _configService: ConfigService
    ){}

    get transactionSources(): Observable<TransactionSource[]> {
        return this._transactionsSources$.asObservable();
    }
    
    set transactionSources(transactionSources: TransactionSource[]){
        this._transactionsSources$.next(transactionSources);
    }

    get totalRows(): Observable<number> {   
        return this._totalRows$.asObservable();
    }

    queryTransactionSources(filter:TransactionSourceFilter = this.defaultFilter): Subscription {
        const cleanedFilter = createCleanedPartial(filter);
        return this._httpClient.post<QueryResult<TransactionSource>>(this._configService.formatUrl('transactionSourceUrl') + "/query", cleanedFilter)
        .pipe(
            // moves the status to a more accessible location, that can be overridden by SSE
            map((result: QueryResult<TransactionSource>) => {
                result.items.map((transactionSource: TransactionSource) => {
                    transactionSource.lastImportStatus = transactionSource.lastTradeFileEvent?.payload?.importStatus || DataImportStatus.UPLOADED;
                })
                return result;
            })
        )
        .subscribe({
            next: (transactionSources: QueryResult<TransactionSource>) => {
                this._transactionsSources$.next(transactionSources.items);
                this._totalRows$.next(transactionSources.rows);
            },
            error: (error:any) => {
                console.error(error);
            }
        });
    }

    ngOnDestroy(): void {
        this._transactionsSources$.complete();
    }

    uploadSingleFile(file:File, companyId?:number):Observable<HttpEvent<EditResult>>{
        const formData = new FormData();
        formData.append('filePart',file);
        let options :any= { 
            reportProgress: true ,
            observe: 'events'
        }
        if(companyId){
          options = {
            ...options,
            params: { companyId: companyId }
          };
        }
        return this._httpClient.post<EditResult>(this._configService.formatUrl('transactionSourceUrl')+"/upload-file", formData, options);
    }

    putFile(file: File,sourceId:string): Observable<HttpEvent<EditResult>> {
        const formData = new FormData();
        formData.append('filePart',file);
        let options :any= { 
            reportProgress: true ,
            observe: 'events',
            params: { sourceId: sourceId }
        }
        return this._httpClient.put<EditResult>(this._configService.formatUrl('transactionSourceUrl') + "/edit-file", formData, options);
    }

    loadTransactionSource(sourceId: number): Observable<TransactionSource[]> {
        return this._httpClient.post<QueryResult<TransactionSource>>(this._configService.formatUrl('transactionSourceUrl')+ "/query", {sourceId: sourceId})
        .pipe(
            map((result: QueryResult<TransactionSource>) => {
                return result.items;
            })
        );
    }

    loadTradeFileEvents(sourceId: number): Observable<TradeFileEvent[]> {
        return this._httpClient.post<QueryResult<TradeFileEvent>>(this._configService.formatUrl('tradeFileEventUrl')+'/query', {sourceId:sourceId, orderBy: 'created', orderDir: 'DESC'}).pipe(
            map((result: QueryResult<TradeFileEvent>)=>{
                return result.items;
            })
        );
    }

    downloadSourceFile(sourceId: number): Observable<any> {
        return this._httpClient.post(this._configService.formatUrl('transactionSourceUrl') + "/download-file" , {}, {
            responseType: 'blob',
            params: { sourceId: sourceId }
        });
    }

    updateSourceSupportStatus(sourceId?: number, supportStatus: SupportStatus = SupportStatus.REQUESTED): Observable<EditResult|null> {
        if(!sourceId){
            return of(null);
        }
        return this._httpClient.post<EditResult>(this._configService.formatUrl('transactionSourceUrl') + "/request-support", {}, {
            params: {
                sourceId: sourceId, 
                status: supportStatus
            }
            })
            .pipe(take(1));
    }

}